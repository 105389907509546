<template> 
  <v-card>
    <v-card-title>
      IPurchase Information
      <v-spacer></v-spacer>
      <v-btn 
        @click="addProduct()" 
        class="primary mr-2">
        Add Product
      </v-btn>
      <v-btn 
        @click="$router.push({ name: 'ipurchase' })" 
        class="primary">
        Back
      </v-btn>
    </v-card-title>
      <v-container>
          <v-expansion-panels v-if="showResults">
              <v-expansion-panel>
                <v-expansion-panel-header>
                  iPurchase Data
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-data-table 
                    :headers="headers" 
                    :items="data.purchaseDetails" 
                    hide-default-footer
                    disable-pagination>

                      <template v-slot:[`item.productImage`]="{ item }">

                        <v-img height="300px" width="300px" :src="item.productImage"
                          @click="zoom(item.productImage)" />

                        <v-dialog v-model="selectedImage" max-width="750px">
                          <v-img height="750px" width="750px" :src="selectedUrl" />
                        </v-dialog>

                      </template>

                      <template v-slot:[`item.receiptImage`]="{ item }">

                        <v-img height="300px" width="300px" :src="item.receiptImage"
                          @click="zoom(item.receiptImage)" />

                        <v-dialog v-model="selectedImage" max-width="750px">
                          <v-img height="750px" width="750px" :src="selectedUrl" />
                        </v-dialog>

                      </template>
                  </v-data-table>
                      <br><br>
                </v-expansion-panel-content>
              </v-expansion-panel>
          </v-expansion-panels>
          
          <v-expansion-panels v-if="showResult">
            <v-expansion-panel>
                <v-expansion-panel-header>
                  Product Data
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <div v-for="(info, index) in data.purchaseData[0].data" :key="index">
                    <v-card class="mx-auto my-12" max-width="374">
                      <v-card-title>Product Data</v-card-title>
                        <v-card-text>
                          <div>
                              <p>
                                Product Name : <span style="color: #1a66ff">{{ info.product }}</span>
                              </p>
                              <p>
                                Product SKU : <span style="color: #1a66ff">{{ info.sku }}</span>
                              </p>
                              <p>
                                Product Quantity Purchased : <span style="color: #1a66ff">{{ info.quantity}} </span>
                              </p>
                              <p>
                                Product Price : <span style="color: #1a66ff">RM {{ info.price }}</span>
                              </p>
                              <p>
                                Category : <span style="color: #1a66ff">{{ info.category }}</span>
                              </p>
                              <p>
                                Flavour: <span style="color: #1a66ff"> {{ info.flavour }}</span>
                              </p>
                          </div>
                        </v-card-text>
                    </v-card>
                  </div>
                </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>

          <v-expansion-panels v-if="showAddProduct">
              <v-expansion-panel>
                <v-expansion-panel-header>
                  Add Product
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-form @submit="createData">
                    <v-container>
                        <p v-if="errors.length">
                          <b>Please correct the following error(s):</b>
                          <ul>
                            <li v-for="(error, index) in errors" :key="index">{{ error }}</li>
                          </ul>
                        </p>
                        <v-card class="mx-auto my-12" max-width="374">
                          <v-card-title>Product Data</v-card-title>
                          <v-card-text>

                          <v-row>
                            <v-col>
                              <v-autocomplete
                                v-model="dataToSend.category" 
                                label="Category"
                                v-validate="'required'" 
                                required 
                                chips 
                                deletable-chips 
                                small-chips
                                dense 
                                :items="categories"
                                outlined 
                                data-vv-name="category">
                              </v-autocomplete>
                            </v-col>
                          </v-row>

                            <v-row>
                              <v-col>
                                <v-text-field 
                                  v-model="dataToSend.product" 
                                  label="Product Name"
                                  v-validate="'required'" 
                                  required 
                                  chips 
                                  deletable-chips 
                                  small-chips
                                  dense 
                                  outlined 
                                  data-vv-name="product">
                                </v-text-field>
                              </v-col>
                            </v-row>

                            <v-row>
                              <v-col>
                                <v-text-field 
                                  v-model="dataToSend.sku" 
                                  label="Product SKU"
                                  v-validate="'required'" 
                                  required 
                                  chips 
                                  deletable-chips 
                                  small-chips
                                  dense 
                                  outlined 
                                  data-vv-name="sku">
                                </v-text-field>
                              </v-col>
                            </v-row>

                            <v-row>
                              <v-col>
                                <v-text-field 
                                  v-model="dataToSend.quantity" 
                                  label="Product Quantity"
                                  v-validate="'required'" 
                                  required chips 
                                  deletable-chips 
                                  small-chips
                                  dense 
                                  outlined 
                                  data-vv-name="quantity">
                                </v-text-field>
                              </v-col>
                            </v-row>
                            
                            <v-row>
                              <v-col>
                                <v-text-field 
                                  v-model="dataToSend.price" 
                                  label="Product Price"
                                  v-validate="'required'" 
                                  required 
                                  chips 
                                  deletable-chips 
                                  small-chips
                                  dense 
                                  outlined 
                                  data-vv-name="price">
                                </v-text-field>
                              </v-col>
                            </v-row>

                            <v-row>
                              <v-col>
                                <v-text-field 
                                  v-model="dataToSend.flavour" 
                                  label="Flavour"
                                  v-validate="'required'" 
                                  required 
                                  chips 
                                  deletable-chips 
                                  small-chips
                                  dense 
                                  outlined 
                                  data-vv-name="flavour">
                                </v-text-field>
                              </v-col>
                            </v-row>

                              <v-btn 
                                type="submit" 
                                class="primary">
                                Add Product
                              </v-btn>

                          </v-card-text>
                        </v-card>
                    </v-container>
                  </v-form>
                </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
      </v-container>
  </v-card>
</template>


<script>
import RestResource from "@/services/dataServiceMobile.js";
const service = new RestResource();

export default {
  data() {
    return {
      data: [],
      headers: [
        { text: "User ID", value: "userId", align: "center" },
        { text: "Product Image", value: "productImage", align: "center" },
        { text: "Receipt Image", value: "receiptImage", align: "center" },
        { text: "Status", value: "status", align: "center" },
      ],
      dataToSend: [],
      errors: [],
      product: null,
      sku: null,
      quantity: null,
      price: null,
      showErrorMessage: false,
      showResults: false,
      showResult: false,
      showAddProduct: false,
      selectedImage: false,
      selectedUrl: "",
      categories: ["Beverage", "Food", "Healthcare", "Home Appliances", "Automobile", "Consumer Electronics", "OTT & Home Care", "Tobacco", "Petcare", "Retail", "Travel", "Telco", "Bnking and insurance"]
    };
  },

  mounted() {
    this.loadDetails();
  },

  methods: {
    loadDetails() {
      this.$setLoader();
      service.getPurchaseData({ _id: this.$route.params.id }).then((res) => {
        this.data = res.data.data[0];
        if (this.data.purchaseDetails.length > 0) {
          this.showResults = true;
          this.$disableLoader();
        }
        if (this.data.purchaseData.length > 0) {
          this.showResult = true;
          this.$disableLoader();
        } 
        else {
          this.showErrorMessage = true;
          this.$disableLoader();
        }
      });
    },
    zoom(url) {
      this.selectedImage = true;
      this.selectedUrl = url;
    },

    addProduct() {
      this.$setLoader();
      this.showAddProduct = true;
      this.$disableLoader();
    },

    createData: function (e) {
      let id = this.data.purchaseDetails[0].userId;
      let receiptId = this.data.purchaseDetails[0]._id;
      if (
        this.dataToSend.product &&
        this.dataToSend.sku &&
        this.dataToSend.quantity &&
        this.dataToSend.price &&
        this.dataToSend.category &&
        this.dataToSend.flavour
      ) {
        this.$setLoader();
        let data = {
          data: {
            product: this.dataToSend.product,
            sku: this.dataToSend.sku,
            quantity: this.dataToSend.quantity,
            price: this.dataToSend.price,
            category: this.dataToSend.category,
            flavour: this.dataToSend.flavour
          },
          userId: id,
          receiptId: receiptId,
          status: "true",
        };
        service.addProduct(data).then((res) => {
          this.$showSuccessMessage(res.data);
          this.$router.go(0);
        }).catch(e => {
          this.$showSuccessMessage(e);
        })
      }
      this.errors = [];

      if (!this.dataToSend.product) {
        this.errors.push("Product Name is required.");
      }
      if (!this.dataToSend.sku) {
        this.errors.push("Product SKU is required.");
      }
      if (!this.dataToSend.quantity) {
        this.errors.push("Product Quantity is required.");
      }
      if (!this.dataToSend.price) {
        this.errors.push("Product Price is required.");
      }
      if (!this.dataToSend.category) {
        this.errors.push("Category is required.");
      }
      if (!this.dataToSend.price) {
        this.errors.push("Flavour is required.");
      }

      e.preventDefault();
    },
  },
};
</script>

<style scoped lang="scss">
@import "~bootstrap/scss/bootstrap.scss";

.bold {
  font-weight: bold;
  max-width: 40%;
}
</style>